angular.module('MyHippoCommons.Directives').directive('startEndorsementModal', function () {
    return {
        scope: {},
        restrict: 'E',
        transclude: true,
        templateUrl: '/modals/start-endorsement/start-endorsement.html',
        controller: function ($scope, PolicyService, $mdDialog) {
            const tomorrow = moment().add(1, 'days');
            const effectiveDate = moment(PolicyService.policyInfo.effective_date);
            const updateDate = tomorrow.isAfter(effectiveDate) ? tomorrow : effectiveDate;

            $scope.forms = {};
            $scope.data = {
                updateDate: updateDate.format('MM/DD/YYYY'),
                updateReason: 'insured_request',
            };

            $scope.draftIsValid = true;
            const transactionLastModified = moment(PolicyService.transactionLastModified);
            $scope.validModifiedDates = 30;
            if (transactionLastModified.isValid()) {
                $scope.draftIsValid = moment().diff(moment(transactionLastModified), 'days') < $scope.validModifiedDates;
            }

            $scope.startEndorsement = function () {
                if ($scope.forms.formData.$valid) {
                    $mdDialog.hide($scope.data);
                }
            };

            $scope.cancel = function () {
                $mdDialog.cancel();
            };
        },

    };
});
